@import '../src/assets/theme/Variable.scss';

@media screen and (max-width: 1599.98px) {
  .chat_sc {
    .lt {
      max-height: calc(100vh - 288px);
    }

    .chat_box {
      max-height: calc(100vh - 288px);
      min-height: calc(100vh - 288px);
    }
  }
}

@media screen and (max-width: 1199.98px) {

  h1,
  .hd_1 :where(h1, h2, h3, h4, h5, h6) {
    font-size: 30px;
  }

  h2,
  .hd_2 :where(h1, h2, h3, h4, h5, h6) {
    font-size: 25px;
  }

  .btnn,
  button.MuiButton-root {
    gap: 8px;
    font-size: 15px;
    min-height: 42px;
    padding: 5px 20px;
  }

  .redsgn_sc {
    padding: 40px 0;
  }

  .home_cta_sc .s_head h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .redsgn_mn .redsgn_lft p {
    font-size: 16px;
    line-height: 26px;
    margin: 10px 0 15px;
  }

  .redsgn_mn .redsgn_lft {
    width: 100%;
    text-align: center;
  }

  .redsgn_mn .redsgn_ryt {
    display: none;
  }

  .site_header {
    .site_hamburgur {
      width: 36px;
      height: 36px;
      min-width: 36px;
      display: inline-flex !important;
      background-color: rgba(255, 255, 255, 0.2);
      justify-content: center;

      &::before {
        display: none;
      }

      svg {
        margin: 0 !important;
        font-size: 22px;
      }
    }

    .nav {
      .site_menu {
        left: 0;
        top: 60px;
        width: 100%;
        position: fixed;
        align-items: stretch;
        flex-direction: column;
        height: calc(100vh - 60px);
        transform: translateX(-100%);
        background: linear-gradient(to right,
            #fff 40%,
            rgba(0, 0, 0, 0.6) 40%);
        transition: $transition;
        padding: 20px 0 20px 5%;
        overflow: auto;
        gap: 0;

        &.active {
          transform: translateX(0%);
        }

        &::before {
          display: none;
        }

        li {
          width: 100%;
          max-width: 300px;

          a {
            &:not(.site_logo) {
              min-height: 30px;
            }
          }

          .sub_menu {
            opacity: 1;
            visibility: visible;
            position: initial;
            transform: none;
            padding-top: 0;
            display: none;
            width: 100%;

            ul {
              background: transparent;
              box-shadow: none;
              border-radius: 0;
              padding: 2px 0 0 12px;

              li {
                flex-direction: column;

                a {
                  color: #000;
                  font-size: 12px;
                  padding-inline: 0;
                  min-height: 30px;
                }

                ul {
                  opacity: 1;
                  position: initial;
                  visibility: visible;
                  padding: 2px 0 10px;
                  transform: none;
                  width: 100%;

                  li {
                    a {
                      padding-inline: 12px;
                    }

                    &:not(.active) {
                      a {
                        opacity: 0.6;
                      }
                    }
                  }
                }
              }
            }
          }

          &.selected {
            .sub_menu {
              display: block;
            }
          }
        }
      }

      .rt {
        position: initial;

        &::before {
          display: none;
        }
      }
    }
  }

  .common_drop {
    .MuiPaper-root {
      .MuiList-root {
        padding-top: 5px;
        padding-bottom: 5px;

        li {
          a {
            padding: 5px 12px;
          }
        }
      }
    }
  }

  .site_footer {
    .single {
      max-width: 240px;

      p {
        font-size: 14px;
      }

      h2 {
        font-size: 16px;
      }

      .nav_list {
        li {
          font-size: 14px;
          margin-bottom: 7px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .form {
        input {
          font-size: 14px;
          border-radius: 5px 0 0 5px;
          padding: 0 10px;
        }

        .btnn {
          font-size: 14px;
          font-weight: 600;
          border-radius: 0 5px 5px 0;
          padding: 0 8px;
        }
      }

      .social_links {
        margin-bottom: 40px;
        gap: 15px;
      }
    }

    .site_footer .copywrite p {
      font-size: 14px;
    }
  }

  .site_breadcrum {
    padding: 12px 0;

    ul li {
      font-size: 14px;
    }
  }

  .account_page {
    .site_breadcrum {
      padding: 12px 0;

      ul li {
        font-size: 14px;
      }
    }
  }

  .location_head {
    .filter-tabs {
      margin-bottom: 10px;

      .filter-tab {
        font-size: 13px;
        min-width: 100px;
        min-height: 35px;

        .MuiChip-label {
          padding: 0;
        }
      }
    }
  }

  .findAgent_grid {
    --gap_x: 15px;
    --gap_y: 15px;

    .findAgent_item {
      min-height: 1px;
      flex-direction: column;
      width: calc(100% / 3 - var(--gap_x));

      >figure {
        width: 100%;
        min-width: 100%;
        border-right: none;
        border-bottom: 1px solid #e6e6e6;
        height: 180px;

        img {
          position: initial;
        }
      }

      .agent_info {
        width: 100%;
        padding: 13px 15px 15px;

        .agent_logo {
          width: 65px;
          height: 40px;
          right: auto;
          left: 0;
          transform: translate(0, -100%);
          background-color: #fff;
        }

        h2 {
          font-size: 18px;
          padding-right: 60px;
        }
      }
    }
  }

  .videoBtn_floating {
    right: 20px;
    bottom: 20px;
  }

  .homeBanner_slide {
    min-height: 400px;

    h1 {
      font-size: 30px;
      margin-bottom: 8px;
    }

    .conta_iner {
      >p {
        font-size: 16px;
      }
    }
  }

  .prp-tlk {
    bottom: 110px;
  }

  .hm_frm_mn {
    max-width: 780px;
    margin-top: 20px;

    .hm_frm_inr {
      padding: 15px;
      border-radius: 10px 10px 0 0;
      gap: 10px;

      .hm_frm {
        width: calc(24% - (10px * 3 / 4));

        &.v2 {
          width: calc(28% - (10px * 3 / 4));
        }
      }

      button {
        top: 15px;
        right: 15px;
      }
    }

    .frm_inr {
      border-radius: 0 0 10px 10px;
      padding: 10px 15px;

      p {
        font-size: 13px;

        figure {
          display: inline-flex;
          position: relative;
          top: -1px;

          img {
            height: 20px;
          }
        }
      }
    }
  }

  .home_properties_sc,
  .myAds_sc,
  .home_blogs_sc,
  .home_investment_sc {
    .s_head {
      margin-bottom: 15px;

      h2 {
        font-size: 25px;
      }
    }
  }

  .property_box {
    .prop_info {
      width: 100%;
      padding: 12px 10px 15px;

      .tags {
        gap: 5px;

        li {
          font-size: 10px;
        }
      }

      h3 {
        font-size: 14px;
        margin-block: 12px 6px;

        span {
          font-size: 13px;
        }
      }

      h4 {
        font-size: 15px;
      }

      >p {
        font-size: 13px;
      }

      .prop_lst {
        gap: 10px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        li {
          font-size: 14px;
        }
      }
    }

    .prop_action {
      width: 100%;
      padding: 8px 10px;
      gap: 3px;

      .MuiButtonBase-root {
        min-height: 26px;

        &.btnn_icon {
          max-width: 26px !important;
          min-width: 26px;
        }

        img {
          display: none;
        }
      }
    }

    &.v2 {
      width: calc(50% - var(--gap_x));

      .prop_info {
        padding: 12px 10px 15px;
        width: 100%;

        h4 {
          font-size: 16px;
        }

        .prop_lst {
          margin-bottom: 0;
        }
      }

      >figure {
        min-width: 100%;
        max-width: 100%;
        aspect-ratio: 1 / 0.5;
        height: auto;
      }

      .prop_action {
        position: relative;
        padding-top: 42px;

        .time {
          top: 6px;
          left: 10px;
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  .propery_listing_sc {
    .lt_s {
      width: 100%;
      padding-right: 0;
    }

    .ads_sc {
      display: none;
    }

    .property_box {
      &.v2 {
        >figure {
          min-width: 100%;
          max-width: 100%;
        }

        .prop_info {
          width: 100%;
          padding: 12px 10px 15px;

          h4 {
            font-size: 16px;
          }

          .prop_lst {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .blogs_grid,
  .blogs_row {
    .blogs_item {
      figure {
        aspect-ratio: 1/0.65;
        border-radius: 10px;
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .home_download_sc {
    min-height: 250px;

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
    }

    .flex {
      margin-top: 10px;
      gap: 5px;

      .MuiButton-root {
        figure {
          img {
            height: 40px;
          }
        }
      }
    }

    .app_image {
      padding-top: 20px;
    }
  }

  .home_cta_sc {
    padding: 20px;
    width: 100%;
  }

  .property_value_lft {
    width: 100%;
    margin-bottom: 30px;
  }

  .propValue_form .form_btn {
    justify-content: center;
  }

  .property_value_sc .s_head {
    text-align: center;
  }

  .property_value_sc .propValue_form {
    margin: 0 auto;
  }

  .lctn_frm {
    .control_group {
      &:has(.more_filter) {
        margin-left: 0;
      }
    }
  }

  .lctn_flx {
    .lctn_lt {
      max-width: 350px;
      min-width: 350px;

      .property_box {
        &.v2 {
          width: calc(100% - var(--gap_x));
        }
      }
    }

    .lctn_rt {
      width: calc(100% - 310px);
    }
  }

  .blogs_row {
    .blogs_item {
      padding: 15px;
    }
  }

  .blogDetail_sc {
    .s_head {
      margin-bottom: 20px;
    }
  }

  .propDetail_sc {
    .prop_topImg {
      figure {
        &:nth-child(1) {
          height: 350px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          height: 170px;
        }
      }
    }

    .prop_mainHead {
      .prop_agentBox {
        padding: 10px;
      }

      .lt_s {
        h1 {
          gap: 12px 20px;

          span {
            font-size: 25px;
          }

          .MuiButtonBase-root {
            font-size: 12px;
            min-height: 30px;
          }
        }
      }
    }

    .prop_mainBody {
      .lt_s {
        width: 100%;
        padding-right: 0;
      }

      .prop_ads {
        display: none;
      }

      .single {
        .provider_box {
          max-width: 100%;
          padding: 15px;
        }
      }
    }
  }

  .alert_sc {
    .alert_wrapper {
      .alert_item {
        width: calc(50% - 10px);
      }
    }
  }

  .upload_image.upload_property {
    .upload_image_holder {
      border-radius: 5px;
      font-size: 14px;
    }
  }

  .profile_sc {
    .inner {
      >.lt {
        width: 100%;
        min-width: 100%;
        position: initial;

        .aside_box {
          margin-bottom: 0;
          max-height: initial;
          position: relative;

          .account_menu {
            width: 65px;
            height: 65px;
            color: $c_white;
            background-color: $c_black;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            position: absolute;
            right: 10px;
            top: 10px;

            svg {
              font-size: 30px;
            }
          }

          &:has(.account_menu) {
            .profile_btn {
              width: calc(100% - 75px);
            }
          }

          ul {
            display: none;

            li {
              &:not(:last-child) {
                margin-bottom: 3px;
              }

              a {
                padding: 5px 10px;
                border-radius: 5px;
                background-color: $c_grey;

                figure {
                  border-color: rgba(0, 0, 0, 0.1);
                  background-color: #fff;
                }

                svg {
                  display: block;
                  margin: 0 0 0 auto;
                }

                &.active {
                  figure {
                    border-color: $c_primary;
                    background-color: $c_primary;
                  }
                }
              }
            }
          }

          &.active {
            ul {
              display: block;
            }
          }
        }

        >.MuiButtonBase-root {
          display: none;
        }
      }

      >.rt {
        width: 100%;
        background-color: transparent;
      }
    }
  }

  .account_content {
    padding: 20px 15px 20px;

    .s_head {
      margin-bottom: 15px;
      gap: 15px;

      .uh_spc,
      .uht_spc {
        padding-top: 30px;
      }
    }

    .account_head {
      &.flex.button_tabs {
        .MuiTabs-root {
          .MuiButtonBase-root {
            font-size: 13px;
            padding: 5px 15px;
          }
        }
      }
    }
  }

  .MuiDataGrid-root {
    .MuiDataGrid-row {
      .MuiDataGrid-cell--textLeft {
        button {
          &.MuiButton-root {
            min-height: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .top_boxes {
    &.gap_m {
      --gap_x: 15px;
      --gap_y: 15px;
    }
  }

  .agent_grid,
  .agent_slider {
    .single_list {
      >.MuiButton-root {
        padding: 3px 15px;
      }
    }
  }

  .subscription_slider {
    width: calc(100% + 15px);
  }

  .no-properties-container {
    min-height: 1px;
    max-height: initial;
    padding: 20px 15px;
    margin: 0px auto;
    gap: 15px;

    label {
      margin-bottom: 0;
    }
  }

  .button_tabs {
    .MuiTabs-root {
      .MuiButtonBase-root {
        font-size: 14px;
        padding: 5px 20px;
        min-height: 40px;
      }
    }
  }

  .insights_page {
    .insights_tabs {
      .MuiTabs-root {
        .MuiButtonBase-root {
          font-size: 13px;
          letter-spacing: 0;
        }
      }
    }

    .bx_shadow {
      padding: 20px 15px;
    }
  }

  .companyPerformance_sc {
    .overall_grid {
      .overall_item {
        h2 {
          font-size: 16px;
        }

        .legend {
          .legend_item {
            font-size: 14px;
            gap: 6px;

            .legend_color {
              width: 10px;
              height: 10px;
            }
          }
        }

        .rank_box {
          padding: 8px 5px 8px 50px;
          border-radius: 5px;
          margin-top: 15px;

          svg {
            left: 8px;
          }

          p {
            font-size: 14px;
            margin-top: -1px;
          }

          a {
            font-size: 12px;
            line-height: 1.2;
          }
        }
      }
    }

    .overall_charts {
      .overall_charts_item {
        a {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }
  }

  .insights_page {
    .insights_content {
      .insight_head {
        &.main {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .listingPerformance_sc {
    .filters {
      flex-wrap: wrap;

      .control_group {
        flex: auto;
        width: calc(100% / 3 - (10px * 2 / 3));
      }
    }

    .insight_card {
      .insight_count {
        .card_number {
          max-width: initial;
          width: calc(100% / 5 - (10px * 4 / 5));
        }
      }
    }
  }

  .agentInsight_sc {
    .agent_grid {
      gap: 15px;

      .agent_item {
        width: calc(50% - (15px / 2));
      }
    }
  }

  .leadInsight_sc {
    .lead_grid {
      gap: 15px;

      .lead_item {
        &.w_40 {
          width: calc(40% - (15px / 2));
        }

        &.w_60 {
          width: calc(60% - (15px / 2));
        }

        h2 {
          text-align: center;
        }

        h3 {
          font-size: 22px;
        }

        .pie_legend {
          gap: 5px 15px;

          p {
            font-size: 14px;
          }
        }

        .head_flex {
          flex-direction: column;
          align-items: stretch;
          text-align: center;
          margin-bottom: 20px;
          gap: 12px;

          .graph_btns {
            justify-content: center;
            flex-wrap: wrap;
          }
        }
      }
    }

    .call_grid {
      gap: 15px;

      .call_item {
        &.w_40 {
          width: calc(40% - (15px / 2));
        }

        &.w_60 {
          width: calc(60% - (15px / 2));
        }

        h2 {
          text-align: center;
        }

        h3 {
          font-size: 22px;
        }

        .head_flex {
          flex-direction: column;
          align-items: stretch;
          text-align: center;
          margin-bottom: 20px;
          gap: 12px;

          .graph_btns {
            justify-content: center;
            flex-wrap: wrap;
          }
        }

        .call_data {
          li {
            h3 {
              font-size: 14px;
            }

            h4 {
              font-size: 25px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .partner_banner_sc {
    min-height: 250px;
    padding: 30px 0;

    h1 {
      font-size: 30px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
    }
  }

  .partner_form_sc {
    .form {
      h2 {
        font-size: 25px;
      }
    }
  }

  .appProperty_sc {
    .lt {
      h2 {
        font-size: 28px;
      }

      p {
        font-size: 16px;
        margin: 15px 0 20px;
      }
    }
  }

  .agentDetail_sc {
    .agent_top {
      .lt_box {
        .lt_box_flex {
          >figure {
            width: 120px;
            height: 120px;
          }

          .info_rt {
            width: calc(100% - 120px);

            h1 {
              font-size: 22px;
            }

            p {
              &:not(:last-child) {
                margin-bottom: 5px;
              }
            }

            .agent_rating {
              strong {
                font-size: 18px;
              }

              i {
                svg {
                  font-size: 20px;
                }
              }
            }
          }
        }

        >ul {
          li {
            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .summary_grid {
      flex-wrap: wrap;
      padding: 0;

      .summary_item {
        width: 50%;
        padding: 20px;

        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid #e6e6e6;
        }

        &:nth-child(2) {
          border-right: none;
        }
      }
    }

    .area_grid {
      flex-wrap: wrap;
      gap: 15px;

      .area_item {
        width: calc(100% / 3 - (15px * 2 / 3));
      }
    }

    .agent_mainBody {
      .single {
        .award_btn {
          .MuiButtonBase-root {
            max-width: 350px;
            min-height: 60px;
          }
        }
      }
    }
  }

  .modal:has(.gpt_inner) {
    .modal-dialog {
      max-width: 850px;

      .modal-body {
        padding: 35px;
      }
    }
  }

  .chat_sc {
    .inner {
      --gap_x: 20px;
      --gap_y: 20px;
    }

    .lt {
      padding: 10px;
      max-height: calc(100vh - 252px);
      border-radius: 10px;
    }

    .chat_single {
      padding: 12px 10px;
      border-radius: 5px;
      gap: 5px;

      .time {
        top: 6px;
        right: 6px;
        font-size: 10px;
      }

      figure {
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-width: 1px;
      }
    }

    .chat_box {
      .chat_head {
        padding: 12px 15px;
        gap: 10px;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      .chat_body {
        padding: 15px 15px;

        .single_message {
          .msg_body {
            padding: 12px 12px;

            p {
              font-size: 14px;

              &:not(:last-child) {
                margin-bottom: 10px;
              }

              &.msg_date {
                font-size: 11px !important;
              }
            }
          }

          &.recieved {
            .msg_body {
              border-radius: 5px 5px 5px 0;
            }
          }

          &.sended {
            .msg_body {
              border-radius: 5px 5px 0 5px;

              p {
                font-weight: 400;
              }
            }
          }
        }
      }

      .chat_foot {
        padding-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 991.98px) {
  .site_header {
    .nav {
      .site_menu {
        li {
          max-width: 230px;
        }
      }
    }
  }

  .site_footer {
    .footer_nav {
      flex-wrap: wrap;
      gap: 30px 20px;
    }

    .single {
      &:first-child {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .findAgent_grid {
    .findAgent_item {
      width: calc(100% / 2 - var(--gap_x));
    }
  }

  .MuiPagination-root {
    padding-top: 35px;

    .MuiPagination-ul {
      gap: 2px;

      li {
        button {
          min-width: 35px;
          height: 35px;
        }
      }
    }
  }

  .hm_frm_mn {
    .hm_frm_inr {
      padding: 25px 15px;

      .hm_frm {
        width: calc(100% / 2 - 15px / 2);

        &.v2 {
          width: calc(100% / 2 - 15px / 2);

          &:first-child {
            width: 100%;

            .control_group {
              max-width: 200px;

              .MuiRadioGroup-root.radioButton label {
                height: 35px;
              }
            }
          }
        }
      }

      .hm_txt {
        width: 100%;
      }

      button {
        width: 100%;
        position: initial;
      }
    }
  }

  .bnr_cta {
    .conta_iner {
      .inner {
        min-height: 140px;
        padding: 15px 20px;
        background-size: 60% 100%;

        h2 {
          font-size: 20px;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .home_properties_sc,
  .myAds_sc,
  .home_blogs_sc,
  .home_investment_sc {
    .s_head {
      h2 {
        font-size: 22px;
      }

      .link_text,
      a.link_text {
        font-size: 14px;
      }
    }
  }

  .redsgn_lft,
  .property_value_lft .s_head {
    h2 {
      font-size: 22px;
    }
  }

  .property_grid {
    .property_box {
      width: calc(50% - var(--gap_x));
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .home_download_sc {
    .conta_iner {
      align-items: center;
    }

    .app_image {
      padding-top: 40px;
      position: initial;
      height: 300px;
    }
  }

  .home_cta_sc .s_head h2 {
    font-size: 18px;
  }

  .lctn_flx {
    .lctn_lt {
      max-width: 320px;
      min-width: 320px;
    }

    .lctn_rt {
      width: calc(100% - 280px);
    }
  }

  .blogs_row {
    &.gap_m {
      --gap_x: 15px;
      --gap_y: 15px;
    }

    .blogs_item {
      width: calc(50% - var(--gap_x));
    }
  }

  .propDetail_sc {
    .prop_topImg {
      display: flex;
      flex-wrap: wrap;

      figure {
        &:nth-child(1) {
          height: 300px;
          width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          height: 120px;
          width: calc(50% - 5px);
        }
      }
    }

    .prop_mainHead {
      flex-wrap: wrap;
      gap: 20px;

      .prop_agentBox {
        width: 100%;
        max-width: 100%;
      }

      .lt_s {
        width: 100%;

        h1 {
          margin-bottom: 10px;
        }

        .prop_attributes {
          li {
            flex-direction: row;
            font-size: 14px;
          }
        }
      }
    }

    .prop_mainBody {
      .single {
        padding-top: 20px;

        &:not(:first-child) {
          margin-top: 20px;
        }

        h2 {
          font-size: 20px;
          color: $c_black;
        }

        h3 {
          font-size: 16px;
          color: $c_black;
        }

        p,
        li {
          font-size: 14px;
        }

        ul {
          &.ul_grid {
            gap: 8px 15px;
          }
        }

        .table_grid {
          .table_item {
            table {
              th {
                padding: 7px 8px;
              }

              td {
                font-size: 13px;
                padding: 6px 8px;
              }
            }
          }
        }

        .graph_boxGrid {
          .graph_boxItem {
            padding: 10px;

            .flex {
              p {
                font-size: 14px;
              }

              img {
                min-width: 22px;
                width: 22px;
              }
            }
          }
        }

        .regu_flex {
          .qr_img {
            margin-top: -28px;
            max-width: 130px;

            figure {
              margin-bottom: 7px;
            }

            p {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .prop_mainFoot {
      h2 {
        font-size: 20px;
        margin-bottom: 14px;
        color: $c_black;
      }
    }
  }

  .notification_sc {
    .older {
      ul {
        li {
          .li-item {
            .notification-message {
              p {
                font-weight: 600;
              }

              >p {
                font-size: 14px;
              }
            }

            >p {
              font-size: 14px;
            }
          }

          div:last-child {
            >p {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .plan_list {
    h3 {
      font-size: 16px;
    }

    .currentPlan_box {
      padding: 15px 15px 20px;
      border-radius: 10px;
      gap: 20px;

      .rt ins {
        font-size: 22px;
      }
    }
  }

  .plan_single {
    ins {
      font-size: 22px;
    }

    ul {
      li {
        font-size: 14px;
        margin-left: 18px;
      }
    }
  }

  .insights_page {
    .insights_content {
      .insight_head {

        h1,
        h2 {
          font-size: 20px;
        }
      }
    }
  }

  .companyPerformance_sc {
    .overall_grid {
      gap: 15px;

      .overall_item {
        width: calc(50% - (15px * 2 / 3));
      }
    }
  }

  .listingPerformance_sc {
    .insight_card {
      .insight_count {
        .card_number {
          width: calc(100% / 3 - (10px * 2 / 3));
        }
      }

      .insight_graph {
        gap: 15px;
        flex-wrap: wrap;

        .insight_graph_item {
          &:first-child {
            width: 100%;
          }

          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }

  .leadInsight_sc {
    .lead_grid {
      .lead_item {
        &.w_40 {
          width: 100%;
        }

        &.w_60 {
          width: 100%;
        }

        h3 {
          font-size: 20px;
        }
      }
    }

    .call_grid {
      .call_item {
        width: 100%;

        &.w_40 {
          width: 100%;
        }

        &.w_60 {
          width: 100%;
        }

        h3 {
          font-size: 20px;
        }
      }
    }
  }

  .partner_form_sc {
    .form {
      padding: 25px 20px 30px;
    }
  }

  .ctaBar_sc {
    .s_head {
      flex-wrap: wrap;
      text-align: center;

      h2 {
        width: 100%;
      }
    }
  }

  .appProperty_sc {
    .lt {
      width: 100%;
      text-align: center;
    }

    .rt {
      width: 60%;
      margin-inline: auto;
    }
  }

  .agentDetail_sc {
    .agent_top {
      gap: 20px;

      .lt_box {
        width: 100%;
      }

      .rt_box {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }

    .agent_mainHead {
      .lt_s {
        h2 {
          font-size: 20px;
        }

        p {
          font-size: 14px;
        }
      }

      .rt_s {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .chat_sc {
    .inner {
      --gap_x: 20px;
      --gap_y: 20px;
    }

    .lt {
      width: calc(35% - var(--gap_x));
    }

    .rt {
      width: calc(65% - var(--gap_x));
    }
  }

  .sale_sc {
    .conta_iner {
      >h2 {
        font-size: 20px;

        .MuiButtonBase-root {
          font-size: 20px;
        }
      }
    }

    .s_head {
      align-items: stretch;
      flex-direction: column;
      gap: 20px;
    }
  }

  .home_download_sc {
    h2 {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  body {
    padding-right: 0 !important;

    &[style*='padding-right'] {
      .site_header {
        padding-right: 0;
      }
    }
  }

  :is(body, p, li) {
    font-size: 14px;
  }

  h1,
  .hd_1 :where(h1, h2, h3, h4, h5, h6) {
    font-size: 28px;
  }

  h4,
  .hd_4 :where(h1, h2, h3, h4, h5, h6) {
    font-size: 24px;
  }

  .checkbox_label select,
  .control_group .MuiSelect-select {
    background-position: right 12px center;
  }

  .conta_iner {
    width: 100%;
    padding-inline: 15px;
  }

  .map_vw_comm {
    .conta_iner {
      width: 100%;
      padding-inline: 15px;
    }
  }

  .conta_iner_box,
  .insights_page .conta_iner {
    width: 100%;
    padding-inline: 15px;
  }

  .uh_spc,
  .uhb_spc {
    padding-bottom: 40px;
  }

  .uh_spc,
  .uht_spc {
    padding-top: 40px;
  }

  .content {
    padding-top: 94px;
  }

  .site_logo {
    figure {
      height: 35px;
    }
  }

  .site_header {
    padding: 47px 0 12px 0;

    .conta_iner {
      padding: 0 15px;
    }

    .site_hamburgur {
      width: 32px;
      height: 32px;
      min-width: 32px;

      svg {
        font-size: 20px;
      }
    }

    .nav {
      gap: 15px;

      .search_btn {
        margin-right: 5px;

        img {
          height: 18px;
        }
      }

      .user_btn {
        figure {
          width: 32px;
          height: 32px;
          min-width: 32px;
        }
      }

      .lt {
        gap: 8px;
      }

      .rt {
        padding-left: 0;
        gap: 10px;
      }

      .login_btn,
      .portal_btn,
      .sell_btn {
        padding: 0 12px;
        min-height: 32px;
        font-weight: 600;
      }

      a {
        &:not(.site_logo) {
          min-height: 32px;

          &::before {
            inset: 0px -5px;
          }
        }

        &.language_btn,
        &.location_btn {
          top: 0;
          position: absolute;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.2);
          min-height: 35px;
          width: 50%;

          &::before {
            display: none;
          }
        }

        &.language_btn {
          left: 0;
          border-right: 1px solid #1d1d1d;
        }

        &.location_btn {
          right: 0;
        }
      }

      .site_menu {
        top: 94px;
        height: calc(100vh - 94px);
        background: linear-gradient(to right, #fff 90%, rgba(0, 0, 0, 0.6) 90%);
        padding: 5px 0 20px 15px;
      }
    }
  }

  .site_footer {
    .single {
      max-width: 100%;

      &:last-child {
        width: 100%;
      }

      .social_links {
        margin-bottom: 30px;
      }

      .form {
        width: 100%;
      }
    }

    .copywrite {
      p {
        font-size: 14px;
      }
    }
  }

  .recent_drop {
    min-width: 1px;
  }

  .location_head {
    position: initial;
    background: transparent;

    .filter-tabs {
      margin-bottom: 15px;

      .filter-tab {
        min-width: 80px;
      }
    }

    .top_filter {
      padding: 20px 15px;
      background-color: #f3f3f3;
      border-radius: 10px;
    }

    .lctn_frm {
      .control_group {
        width: 100%;

        input {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }

        .MuiButtonBase-root {
          font-size: 12px;
          width: 100%;

          svg {
            font-size: 20px;
            top: 0px;
          }
        }

        .filter-tab {
          margin-top: 10px;
          min-height: 1px;
          height: auto;
        }
      }
    }

    .back_route {
      margin-bottom: 20px;

      .return {
        gap: 10px;

        figcaption {
          font-size: 14px;
        }
      }
    }

    &.agent_head {
      padding-top: 20px;
    }
  }

  .MuiPagination-root {
    padding-top: 30px;

    .MuiPagination-ul {
      gap: 2px;

      li {
        button {
          min-width: 30px;
          height: 30px;
        }
      }
    }
  }

  .search_drop {
    .MuiPaper-root {
      margin-top: 13px;
      width: 100%;
    }

    .MuiList-root {
      padding: 20px 15px;
    }
  }

  .videoBtn_floating {
    right: 15px;
    bottom: 15px;
    width: 60px;
    height: 60px;
    padding: 10px;
  }

  .prp-tlk {
    bottom: 85px;
    font-size: 9px;

    img {
      width: 18px;
    }
  }

  .float_drop {
    .MuiPaper-root {
      .MuiList-root {
        padding-bottom: 5px;
        min-width: 150px;

        li {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          a {
            min-height: 35px;
            padding: 5px 15px;
            font-size: 13px;

            img {
              top: -1px;
              position: relative;
              margin-right: 2px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .hm_frm_mn {
    .hm_frm_inr {
      .hm_frm {
        width: 100%;

        &.v2 {
          width: 100%;

          &:first-child {
            .control_group {
              max-width: 160px;

              .MuiRadioGroup-root.radioButton label {
                height: 32px;
              }
            }
          }
        }
      }

      .hm_txt {
        width: 100%;
      }

      button {
        width: 100%;
        position: initial;
      }
    }

    .frm_inr {
      padding: 15px;
      flex-direction: column;
      gap: 10px;
    }

    .control_group {

      input,
      .MuiSelect-select,
      .beds_select_home,
      .price_select-drop {
        text-align: left;
        justify-content: flex-start;
      }
    }
  }

  .home_download_sc {
    h2 {
      font-size: 22px;
      text-align: center;
    }

    p {
      font-size: 14px;
      text-align: center;

      br {
        display: none;
      }
    }

    .flex {
      flex-wrap: wrap;
      justify-content: center;
    }

    .app_image {
      padding-top: 30px;
      height: 250px;
    }
  }

  .home_cta_sc {
    .s_head {
      flex-direction: column;
      text-align: center;
      gap: 15px;
    }
  }

  label {
    font-size: 14px;
    margin: 0 0 5px;
  }

  .modal {
    .btn-close {
      top: 5px;
      right: 5px;
    }

    .modal-dialog {
      margin: 15px auto;
      padding: 0 10px;
    }

    .modal-body {
      padding: 35px 15px 25px;
      border-radius: 10px;
    }

    .btn_flex {
      margin-top: 22px;
      gap: 5px;
    }

    .btn_back {
      top: 5px;
      left: 7px;
    }
  }

  .auth_modal {
    .modal-body {
      min-height: 1px;
    }
  }

  .lctn_frm {
    .control_group {

      .price_select-drop,
      .beds_select_home,
      .beds_select,
      .MuiSelect-select {
        max-width: 100%;
        justify-content: flex-start;
      }

      .searchText_field {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }

      .clear_filter {
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
        display: block;
        padding: 0;
      }
    }
  }

  .property_box {
    &.v2 {
      .prop_info {
        h3 {
          font-size: 18px;
          margin-block: 0 7px;
        }
      }
    }
  }

  .hm_prpty_sec {
    .s_head {
      flex-direction: column;
      align-items: stretch;
      gap: 15px;

      .lt_s {
        h1 {
          font-size: 22px;
        }
      }

      .rt_s {
        .MuiButtonBase-root {
          min-height: 40px;
          font-size: 14px;
          width: 100%;
        }
      }
    }
  }

  .float_btn {
    &.active {
      bottom: 15px;
    }

    .MuiButton-root {
      height: 42px;
      padding: 0 20px !important;
      font-size: 14px !important;

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .lctn_flx {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 20px;

    .lctn_lt {
      max-width: 100%;
      min-width: 100%;
    }

    .lctn_rt {
      top: 94px;
      height: 220px;
      width: calc(100% + 30px);
      background-color: $c_white;
      margin: 0 -15px;
      padding: 0;
    }
  }

  .contact_sc {
    .map {
      height: 320px;
      border-radius: 10px;
    }

    h2 {
      margin-top: 40px;
      font-size: 22px;
    }

    ul {
      gap: 10px;
    }

    .form {
      margin-top: 20px;

      .w_50 {
        width: 100%;
      }

      label {
        font-size: 14px;
        margin: 0 0 5px;
      }
    }
  }

  .cms_sc {
    .hd_2 {
      margin-bottom: 15px;
    }

    p {
      h1 {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 25px;
      }
    }
  }

  .contact_sc,
  .faq_sc {
    .s_head {
      margin-bottom: 25px;

      h1 {
        margin-bottom: 10px;
      }
    }
  }

  .faq_sc {
    .MuiAccordion-root {
      .MuiAccordionSummary-root {
        padding: 10px 10px 10px 15px;
      }

      .MuiAccordionDetails-root {
        padding: 0 15px 15px;

        p {
          font-size: 14px;
        }
      }
    }
  }

  .reel_modal {
    .modal-dialog {
      max-width: 100%;
      min-height: 100%;
      align-items: stretch;
      padding: 0;
      margin: 0;
    }

    .modal-body {
      padding: 0;
      border-radius: 0;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 0;
    }

    .close-icon-reel {
      top: 10px;
      right: 10px;
    }

    .reel_sc {
      max-height: 100%;
      height: calc(100vh - 60px);
      border-radius: 0;
    }

    .reel_action {
      padding-left: 0;
      position: initial;
      flex-direction: row;
      width: 100%;

      li {
        margin: 0;
        width: 100%;
        background-color: $c_black;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;
        height: 60px;

        &:not(:last-child) {
          border-right: 2px solid rgba(255, 255, 255, 0.1);
        }

        figure {
          width: 35px;
          height: 35px;
          background-color: rgba(255, 255, 255, 0.1);

          svg {
            font-size: 16px;
          }
        }

        p {
          color: $c_white;
          font-size: 12px;
        }
      }
    }

    .single_reel {
      max-height: initial;
      height: calc(100vh - 60px);
    }
  }

  .blogDetail_sc {
    .s_head {
      margin-bottom: 20px;

      h1 {
        font-size: 22px;
      }
    }

    .blog_info {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 18px;
      }

      h3,
      h4,
      h5,
      h6 {
        font-size: 16px;
      }
    }
  }

  .propDetail_sc {
    .prop_topImg {
      figure {
        border-radius: 5px;

        &:nth-child(1) {
          height: 220px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          height: 80px;
        }
      }
    }

    .prop_mainHead {
      .lt_s {
        h1 {
          span {
            font-size: 22px;
            letter-spacing: -0.3px;
          }

          .MuiButtonBase-root {
            font-size: 11px;
            padding: 0 12px;
          }
        }

        .prop_attributes {
          gap: 10px 20px;

          li {
            font-size: 13px;
            font-weight: 500;

            figure {
              width: 25px;
              height: 25px;
            }

            &:not(:last-child) {
              &::after {
                left: calc(100% + 10px);
                height: 20px;
              }
            }
          }
        }
      }

      .prop_agentBox {
        .inner {
          h2 {
            font-size: 18px;
            font-weight: 700;
          }

          p {
            width: calc(100% + 100px);
          }
        }

        .btn_group {
          gap: 5px;

          .MuiButtonBase-root {
            min-height: 30px;

            img {
              display: none;
            }
          }
        }
      }
    }

    .prop_mainBody {
      .single {
        ul {
          &.ul_grid {
            gap: 10px;

            li {
              width: 100%;
            }
          }
        }

        .table_grid {
          gap: 25px;

          .table_item {
            width: 100%;
          }
        }

        .MuiButtonBase-root {
          font-size: 14px;

          &.sm {
            min-height: 30px;
          }
        }

        .graph_boxGrid {
          .graph_boxItem {
            width: 100%;
          }
        }

        .provider_box {
          flex-wrap: wrap;
          gap: 20px;

          .lt,
          .rt {
            width: 100%;
          }

          .lt {
            .inner {
              gap: 10px;

              figure {
                width: 60px;
                height: 60px;
                min-width: 60px;
              }
            }
          }

          .btn_group {
            .MuiButtonBase-root {
              font-size: 11px;

              img {
                display: none;
              }
            }
          }
        }

        .regu_flex {
          .ul_grid {
            width: 100%;
            margin-bottom: 0;
          }

          .qr_img {
            margin-top: 0;
            max-width: 150px;
            position: initial;
            width: 100%;

            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .propImg_modal {
    .modal-dialog {
      max-width: 100%;
      padding: 0 25px;
    }
  }

  .flx-box {
    ul.aft-none {
      left: 0;
      bottom: 0;
      width: 100%;
      position: fixed;
      background-color: $c_black;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;

      li {
        width: calc(100% / 3);
        border-right: 2px solid rgba(255, 255, 255, 0.1);
        align-items: center;
        justify-content: center;
        color: $c_white;
        height: 50px;
      }
    }
  }

  .alert_sc {
    .alert_wrapper {
      gap: 15px;

      .alert_item {
        width: 100%;
        padding: 15px;
      }
    }
  }

  .postProperty_sc {
    .form {
      .gap_p {
        --gap_x: 15px;
        --gap_y: 15px;

        .w_50 {
          width: 100%;
        }

        .upload_image {
          margin-bottom: 0 !important;
        }
      }

      .upload_image {
        &.w_25 {
          width: 50%;
        }
      }

      .form_btn {
        margin-top: 30px;
        flex-direction: column;
        gap: 5px;

        .MuiButton-root {
          width: 100%;
        }
      }
    }
  }

  .notification_sc {
    .older {
      ul {
        li {
          flex-direction: column;
          align-items: stretch;
          gap: 10px;
        }
      }
    }
  }

  .profile_sc {
    .inner {
      >.rt {
        border-radius: 0;
        border: none;
      }
    }
  }

  .account_content {
    padding: 0;

    .s_head {
      a {
        font-size: 14px;
      }
    }
  }

  .subscription_slider {
    padding-inline: 1px 50px;
  }

  .account_page .conta_iner {
    padding: 0 15px;
  }

  .profile_sc {
    .inner {
      >.lt {
        .aside_box {
          padding: 5px;
          border-radius: 5px;

          .account_menu {
            width: 50px;
            height: 60px;
            right: 5px;
            top: 5px;

            svg {
              font-size: 24px;
            }
          }

          &:has(.account_menu) {
            .profile_btn {
              width: calc(100% - 55px);
            }
          }

          .profile_btn {
            figure {
              width: 40px;
              height: 40px;
              min-width: 40px;
            }
          }

          ul {
            margin-top: 10px;

            li {
              a {
                font-size: 13px;
                padding: 4px 8px;

                figure {
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }
      }
    }

    .s_main_head {
      flex-wrap: wrap;
      gap: 15px;

      .rt {
        gap: 10px;

        .rating_link {
          .MuiIconButton-root {
            font-size: 12px;
            font-weight: 700;

            span {
              width: 35px;
              height: 35px;
              min-width: 35px;

              svg {
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  .account_content {
    .s_head {
      gap: 10px;
    }

    .account_head {
      &.flex {
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        gap: 10px;
      }
    }

    .form {
      .gap_p {
        --gap_x: 15px;
        --gap_y: 15px;
      }

      .w_50 {
        width: 100%;
      }

      .form_btn {
        margin-top: 25px;
        flex-direction: column;

        .MuiButton-root {
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  .agent_box {
    padding: 10px;
    flex-wrap: wrap;
    border-radius: 5px;
    gap: 10px;

    figure {
      width: 60px;
      height: 60px;
    }

    .info_sc {
      width: calc(100% - 70px);

      .agent_location {
        font-size: 12px;
      }
    }

    >.MuiButton-root {
      width: 100%;
      min-height: 40px !important;
    }
  }

  .agent_detail_sc {
    >.s_head {
      &:not(.hd_7) {
        .MuiButton-root {
          padding: 2px 12px;
          min-height: 33px;
          font-size: 13px;
        }
      }

      &.hd_7 a {
        font-size: 14px;
      }
    }

    .personal_info {
      li {
        font-size: 14px;
      }
    }
  }

  .myListing_grid {
    .single_list {
      padding: 5px;
      width: calc(100% - var(--gap_x));
      border-radius: 5px;

      figure {
        width: 70px;
        height: 70px;
        min-width: 70px;
        border-radius: 5px;
      }

      .info_sc {
        padding-right: 30px;

        .prop_location {
          font-size: 12px;
        }

        p {
          margin: 6px 0 4px;
          font-size: 16px;
        }

        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
          font-size: 20px;
          right: 5px;
        }
      }
    }
  }

  .token_transaction {
    li {
      &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .token_box {
    padding: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
    gap: 15px;

    figure {
      width: 70px;
      min-width: 70px;
    }

    p {
      width: calc(100% - 85px);

      strong {
        font-size: 30px;
      }

      span {
        font-size: 14px;
      }
    }

    .MuiButtonBase-root {
      width: 100%;
      min-height: 40px !important;
    }
  }

  .plan_list {
    .currentPlan_box {
      flex-wrap: wrap;
      padding: 10px 15px 15px;
      border-radius: 5px;
      gap: 15px;

      .lt {
        width: 100%;
        text-align: center;
        align-items: stretch;

        figure {
          margin-inline: auto;
        }
      }

      .rt {
        width: 100%;
        align-items: stretch;
        text-align: center;

        ins {
          font-size: 20px;
        }
      }
    }
  }

  .plan_single {
    padding: 0 15px 20px;

    h4 {
      font-size: 16px;
      margin-block: 5px;
    }

    ul {
      li {
        &::before {
          top: 2px;
        }
      }
    }

    .MuiButton-root {
      margin-block: 5px 5px;

      +a {
        font-size: 14px;
      }
    }
  }

  .subs_slider {
    .plan_single {
      width: 100%;
      min-height: 450px;
    }
  }

  .payment_cards {
    .saved_card {
      figure {
        width: 50px;
      }

      p {
        strong {
          font-size: 18px;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .MuiFormControlLabel-root {
      gap: 10px;

      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          width: 22px;
          height: 22px;
        }

        input[type='radio'] {
          width: 22px;
          height: 22px;
          min-width: 22px;
          border-width: 1px;

          &::before {
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }

  .text_tabs .MuiTabs-root .MuiButtonBase-root {
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px 8px;
    letter-spacing: 0;
  }

  .transaction {
    max-height: initial;
    overflow: visible;
  }

  .chip-stack {
    .MuiButtonBase-root {
      height: 30px;
      padding: 0 10px;
      min-width: 70px;
      font-size: 14px;
    }
  }

  .newPayment_modal {
    .chip-stack {
      border-radius: 5px;
      padding: 15px 10px 20px;

      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .button_tabs {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        gap: 5px;
      }

      .MuiButtonBase-root {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }

  .account_content {
    &:has(.button_tabs) {
      margin-top: 100px;

      >.button_tabs {
        top: -100px;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        gap: 15px;
      }
    }

    .account_head {
      &.flex.button_tabs {
        .MuiTabs-root {
          .MuiButtonBase-root {
            flex: 1;
            min-height: 35px;
          }
        }
      }
    }
  }

  .insights_page {
    .insights_tabs {
      padding: 10px 0;
      background-color: $c_white;
      position: sticky;
      z-index: 9;
      top: 94px;

      .MuiTabs-root {
        .MuiTabs-flexContainer {
          flex-wrap: wrap;
          gap: 5px;
        }

        .MuiButtonBase-root {
          font-size: 14px;
          letter-spacing: 0;
          padding: 10px;
          text-transform: capitalize;
          min-height: 30px;
          background-color: $c_grey;
          width: calc(50% - (5px / 2));

          &.Mui-selected {
            color: $c_white;
            background-color: $c_primary;
            font-weight: 600;
          }
        }

        .MuiTabs-indicator {
          display: none;
        }
      }
    }

    .insights_content {
      .insight_head {
        &.flx {
          flex-direction: column;
          align-items: stretch;
          text-align: center;
          margin-bottom: 20px;
          gap: 12px;
        }
      }
    }
  }

  .companyPerformance_sc {
    .overall_grid {
      .overall_item {
        width: 100%;
      }
    }

    .overall_charts {
      .overall_charts_item {
        width: 100%;
      }
    }
  }

  .listingPerformance_sc {
    .filters {
      .control_group {
        width: calc(50% - (15px / 2));
      }
    }
  }

  .leadInsight_sc {
    .call_grid {
      .call_item {
        .call_data {
          gap: 20px 15px;

          li {
            width: calc(100% / 2 - (15px / 2));
          }
        }
      }
    }
  }

  .upload_image.upload_docs .upload_image_holder {
    height: 150px;
  }

  .breakdown_modal,
  .filter_modal,
  .emailAgent_modal,
  .moreFilter_modal {
    .head {
      padding: 15px 15px;
      margin: -35px -15px 0;

      h2 {
        font-size: 18px;
      }
    }
  }

  .emailAgent_modal,
  .moreFilter_modal {
    .filter_body {
      padding: 20px 0 0;
    }
  }

  .filter_modal {
    .filter_box {
      h3 {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        span {
          font-size: 90%;
        }
      }

      .check_select {
        gap: 5px;

        li {
          min-height: 35px;
          padding: 0 12px;
          font-size: 14px;
        }
      }

      .filter_flex {
        gap: 30px;

        .control_group {
          &:first-child {
            width: 100%;
          }

          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }

  .catSelector_pop,
  .moreFilterSelector_menu {
    .MuiPaper-root {
      max-width: calc(100% - 32px);
      width: 100%;
    }
  }

  .partner_banner_sc {
    h1 {
      font-size: 25px;
    }

    p {
      font-size: 14px;
    }
  }

  .partner_form_sc {
    .form {
      padding: 20px 15px 25px;

      h2 {
        font-size: 20px;
      }

      .gap_p {
        .w_50 {
          width: 100%;
        }
      }

      .MuiFormControlLabel-root {
        .MuiTypography-root {
          font-size: 14px;
        }
      }
    }
  }

  .ctaBar_sc {
    .s_head {
      h2 {
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }

  .site_header {
    &.company_header {
      padding: 12px 0 12px 0;

      .nav {
        .rt {
          button.MuiButtonBase-root {
            padding: 0 20px;
            min-height: 35px;
          }
        }
      }
    }
  }

  body {
    &:has(ul.aft-none) {
      padding-bottom: 50px;

      .videoBtn_floating {
        bottom: 65px;
      }
    }

    &:has(.company_header) {
      .content {
        padding-top: 59px;
      }
    }
  }

  .auth_modal {
    .inner {
      &.auth_setup {
        padding-top: 0;
      }

      .control_group {
        .address_icon {
          top: 26px;
          right: 5px;
        }
      }
    }
  }

  .verify_box {
    button.MuiButton-root.verify {
      top: 7px;
      right: 5px;
      font-size: 10px;
      min-height: 26px;
      padding: 0 10px;
      min-width: 1px;
    }
  }

  .homeBanner_slide {
    min-height: 300px;
  }

  .appProperty_sc {
    .lt {
      h2 {
        font-size: 25px;
      }

      p {
        font-size: 14px;
      }
    }

    .rt {
      width: 90%;

      figure {
        margin-bottom: 20px;

        .MuiSvgIcon-root {
          width: 70px;
          height: 70px;
          padding: 10px;
        }
      }
    }
  }

  .agentDetail_sc {
    .agent_top {
      .lt_box {
        padding: 20px 15px 45px;
        position: relative;

        .lt_box_flex {
          >figure {
            width: 80px;
            height: 80px;
          }

          .info_rt {
            padding-left: 10px;
            width: calc(100% - 80px);
            position: initial;

            .share_agent {
              top: auto;
              left: 50%;
              right: auto;
              transform: translate(-50%, 0);
              bottom: 12px;
            }

            h1 {
              font-size: 20px;
              margin-block: 5px;
            }

            p {
              &:not(:last-child) {
                margin-bottom: 2px;
              }
            }

            .agent_rating {
              font-size: 13px;

              strong {
                font-size: 16px;
              }
            }

            .agent_prop_count {
              font-size: 13px;

              strong {
                font-size: 16px;
              }
            }
          }
        }

        .last_flex {
          gap: 10px;
          flex-direction: column;
          align-items: stretch;
          text-align: center;

          .MuiButtonBase-root {
            font-size: 14px;
            min-height: 40px;
          }
        }
      }
    }

    .agent_mainHead {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 15px;
      gap: 10px;
    }

    .summary_grid {
      .summary_item {
        width: 100%;
        padding: 15px;
        border: none !important;

        &:not(:last-child) {
          border-bottom: 1px solid #e6e6e6 !important;
        }

        h3 {
          margin-bottom: 5px;

          span {
            strong {
              font-size: 20px;
            }
          }
        }
      }
    }

    .area_grid {
      .area_item {
        width: calc(50% - (15px / 2));

        figure {
          height: 120px;
        }
      }
    }
  }

  .modal:has(.gpt_inner) {

    [data-testid='sentinelStart'],
    [data-testid='sentinelEnd'] {
      display: none;
    }

    .btn-close {
      z-index: 10;
      position: fixed;
    }

    .modal-dialog {
      margin: 0;
      padding: 0;
      align-items: stretch;
      height: 100%;

      h2 {
        gap: 5px;
      }

      p {
        line-height: 1.4;
        margin-bottom: 0;
      }

      .modal-body {
        padding: 0;
        border-radius: 0;
        height: auto;
      }
    }

    .gpt_inner {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;

      .hd_4 {
        margin-bottom: auto;
        padding: 25px 15px;
        background-color: $c_white;
        position: sticky;
        z-index: 9;
        top: 0;

        h2 {
          font-size: 20px;

          figure {
            display: inline-flex;

            img {
              height: 30px;
            }
          }
        }
      }

      .cht_mn {
        padding: 0 15px;
        flex: 1;
        height: auto;
        overflow: visible;

        .chat_lst {
          min-height: 50px;
          padding: 15px 15px;
          border-radius: 10px 10px 10px 0;
          max-width: calc(100% - 20px);
          scrollbar-width: none;
          margin-bottom: 0;

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          p,
          li {
            color: $c_black;
            font-size: 14px;
          }
        }

        .chat_bg {
          padding: 15px 15px;
          border-radius: 10px 10px 0 10px;
          max-width: calc(100% - 20px);
          margin: 0 0 0 auto;
          position: relative;
          font-size: 14px;

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }

      .chat_frm {
        padding: 15px;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: $c_white;
        position: sticky;
        gap: 5px 0;
        z-index: 9;
        bottom: 0;

        .control_group {
          width: calc(100% - 40px);
          flex: initial;

          input {
            border-radius: 30px;
            padding: 0 50px 0 15px;
          }

          .eye_btn {
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: 3px;
            right: 2px;

            button {
              width: 100%;
              height: 100%;
            }
          }
        }

        >figure {
          width: 35px;
          min-width: 35px;
        }

        >.MuiButtonBase-root {
          width: 100%;
          color: $c_danger;
          border-radius: 0;
          border: none;
          min-height: 20px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .chat_sc {
    .inner {
      --gap_x: 15px;
      --gap_y: 15px;
    }

    .back_icon {
      margin-bottom: 5px;
    }

    .lt {
      width: calc(100% - var(--gap_x));
      max-height: initial;

      &.active {
        display: none;
      }
    }

    .rt {
      width: calc(100% - var(--gap_x));
      display: none;

      &.active {
        display: block;
      }

      .back_icon {
        display: flex;
      }
    }

    .chat_single {
      padding: 15px 10px;
      gap: 7px;
    }

    .chat_box {
      .chat_body {
        .single_message {
          max-width: 280px;
        }
      }
    }
  }

  .sale_sc {
    .conta_iner {
      >h2 {
        font-size: 18px;

        .MuiButtonBase-root {
          font-size: 18px;
        }
      }
    }

    .s_head {
      .lt_s {
        h3 {
          font-size: 20px;
          margin-bottom: 10px;
        }

        ul {
          align-items: stretch;
          flex-direction: column;
          gap: 5px;

          li:not(:last-child) {
            border-right: none;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  .notification_sc {
    .recent {
      ul {
        li {
          padding: 12px 15px;
          flex-direction: column;
          align-items: stretch;
          gap: 5px;

          div:last-child {
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .account_content .account_head.flex+.gap_p .w_25 {
    width: 100%;
  }

  .token_request {
    .flex_btn {
      gap: 10px;

      .MuiButton-root {
        min-width: 1px;
        width: 100%;
      }
    }
  }

  .account_content .account_head.flex {

    .rt_side,
    .lt_side {
      flex-direction: column;
      align-items: stretch;

      .search_bar,
      .control_group.w_50 {
        width: 100%;
        max-width: 100%;
      }

      .search_bar .MuiFormControl-root input {
        width: 100%;
      }
    }
  }

  .drop_lang {
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    width: 50%;

    &::before {
      display: none;
    }

    .skiptranslate {
      .goog-te-combo {
        height: 35px;
        width: 100%;
        text-align: center;
        border-radius: 0;
      }
    }
  }

  .hide_btn {
    display: none !important;
  }

  .site_header {
    .nav {
      .portal_btn {
        display: none;
      }

      .site_menu {
        li.portal_li {
          display: block !important;
          margin-top: 20px;

          .btnn {
            color: $c_black;
            font-size: 14px;
            background-color: $c_primary;
            background-image: url('../public/images/gradient_bg.jpg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: auto;
            font-weight: 600;
            border: none;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575.98px) {
  .findAgent_grid {
    .findAgent_item {
      width: calc(100% - var(--gap_x));
    }
  }

  .redsgn_mn .redsgn_lft p {
    font-size: 14px;
    line-height: 22px;
  }

  .searchText_field .selected_loc p span {
    max-width: 60px;
  }

  .homeBanner_slide {
    h1 {
      font-size: 28px;
    }
  }

  .bnr_cta .conta_iner .inner {
    width: 100%;
  }

  .bnr_cta .conta_iner {
    gap: 10px;
  }

  .property_grid {
    .property_box {
      width: calc(100% - var(--gap_x));
    }
  }

  .blogs_row {
    .blogs_item {
      width: calc(100% - var(--gap_x));
    }
  }

  .top_boxes {
    &.gap_m {
      --gap_x: 10px;
      --gap_y: 10px;
    }

    .box_card {
      width: calc(100% - var(--gap_x));
    }
  }

  .listingPerformance_sc {
    .filters {
      .control_group {
        width: 100%;
      }
    }

    .insight_card {
      .insight_count {
        .card_number {
          width: calc(50% - (15px / 2));
        }
      }
    }
  }

  .agentInsight_sc {
    .agent_grid {
      .agent_item {
        width: 100%;

        .flex_row {
          margin-block: 20px;
          gap: 15px;
        }
      }
    }
  }

  .property_value_sc {
    background-position: left center;
  }

  .plan_single {
    p {
      font-size: 14px;
    }

    .MuiButtonBase-root {
      font-size: 13px !important; 
    }
  }
}